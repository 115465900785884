<template>
  <div
    class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-2 px-6 gap-2"
  >
    <QrCode v-for="item in items" :key="item.qr_id" :item="item" />
  </div>
</template>

<script>
import QrCode from "@/components/QrCode.vue";

export default {
  name: "QRCodes",
  components: {
    QrCode
  },
  computed: {
    items() {
      const selectedHubs = this.$route.query?.hubs;
      const { type } = this.$route.params;
      const { bikes, hubs, checkLists } = this.$store.state;
      if (type == "assets")
        return bikes.bikes.filter(item => {
          return selectedHubs ? selectedHubs.includes(item.hub_id) : true;
        });
      if (type == "hubs")
        return hubs.hubs.filter(item => {
          return selectedHubs ? selectedHubs.includes(item.id) : true;
        });
      if (type == "Checklists") return checkLists.checkLists;
      return [];
    }
  },
  created() {
    this.$store.dispatch("bikes/getAllBikes");
    this.$store.dispatch("hubs/getAllHubs");
    this.$store.dispatch("checkLists/getAllCheckLists");
  }
};
</script>
